<template>
  <v-dialog
    @click:outside="closeDialog"
    v-model="dialog"
  >
    <v-form ref="form">
      <h1>{{ subMemberTypename }}を追加</h1>
      <v-text-field
        :rules="rules.rulesEmail"
        v-model="email"
        label="ログインメールアドレス"
        class="mb-5"
      ></v-text-field>
      <div class="d-flex justify-end mt-1">
        <v-btn class="t-btn--red-dark" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-btn class="t-btn--prm ml-3" @click="save">
          {{ $t('buttons.create') }}</v-btn
        >
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isEmailValid } from "@/utils/validate.js";

export default {
  name: 'SettingLink',
  data() {
    return {
      valid: false,
      row: null,
      isShowDate: false,
      dates: [],
      rules: {
        rulesEmail: [
          (v) => !!v || this.$t('rules.requiredField'),
          (v) =>
            !v ||
            isEmailValid(v) ||
            this.$t('rules.emailIsInvalid'),
        ],
      },
    };
  },
  props: {
    visible: Boolean,
    subMemberTypename: String,
  },
  computed: {
    ...mapGetters(['emailCustomerMC']),
    email: {
      get() {
        return this.emailCustomerMC;
      },
      set(val) {
        this.setEmailCustomerMC(val);
      },
    },
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ');
      },
      set() {
        this.dates.join(' ~ ');
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'createsubmemberCustomerMembershipContract',
      'getClientMemberInfoCustomerMembershipContract',
    ]),
    ...mapMutations([
      'setAlertSuccess',
      'setEmailCustomerMC',
      'setSubMemberTypeIdCustomerMc',
    ]),
    save() {
      if (this.$refs.form.validate()) {
        if (this.subMemberTypename === 'ファミリー会員') {
          this.setSubMemberTypeIdCustomerMc(2);
        } else {
          this.setSubMemberTypeIdCustomerMc(1);
        }
        this.createsubmemberCustomerMembershipContract(
          parseInt(this.$route.params.id),
        ).then((result) => {
          if (this.subMemberTypename === 'ファミリー会員') {
            this.$emit('addFalseToMenuBirthDay2')
          } else {
            this.$emit('addFalseToMenuBirthDay1')
          }
          
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
.v-btn {
  font-size: 12px !important;
  height: 32px !important;
  color: #fff !important;
  i {
    font-size: 14px;
  }
}
.t-btn--red-dark {
  width: 72px !important;
}
.t-btn--prm {
  width: 68px !important;
}
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 332px;
    padding: 17px;
    background-color: #fff !important;
    border-radius: 9px;
    .v-label {
      color: #444444;
      font-size: 12px;
      font-weight: 500;
      &--active {
        font-size: 10px;
        font-weight: 500 !important;
        color: #444444 !important;
      }
    }
  }
  .v-text-field__slot {
    input {
      font-size: 12px !important;
      color: #444444 !important;
      font-weight: 500;
    }
  }
}
</style>
