<template>
  <div>
    <v-dialog @click:outside="closeDialog" v-model="dialog">
      <v-form ref="form">
        <!-- alet -->
        <h1 class="text-header">{{ defaultYearSelected }}年年会費変更</h1>
        <template>
          <div class="d-flex justify-space-between pa-2 div-alert">
            <div class="mt-1">
              <i class="icon-alert fas fa-exclamation-triangle"></i>
              <span class="ml-2 text-alert">未請求の年会費です</span>
            </div>
            <v-btn
              class="btn-billing btn-crm-primary text-12px"
              @click="changeStatus"
            >
              請求作成
            </v-btn>
          </div>
        </template>
        <!-- end alet -->
        <!-- status -->
        <div class="d-flex mt-2">
          <div>
            <h4 class="text-8px text-header-field">ステータス</h4>
            <p class="text-12px mt-2 text-data-field">未請求</p>
          </div>
        </div>
        <!-- end status -->

        <!-- Content -->
        <div>
          <h4 class="text-8px text-header-field">支払い備考</h4>
          <v-text-field
            style="width: 73%"
            v-model="getRemarksCustomerMc"
            :rules="rules.required"
            dense
          ></v-text-field>
        </div>
        <!-- if data.processedAt === null -->
        <div>
          <div>
            <h4 class="text-8px text-header-field">予定請求月</h4>
            <!-- @change="changeMonth($event)" -->
            <v-select
              style="width: 73%"
              dense
              v-model="getMonthCustomerMc"
              item-text="name"
              item-value="id"
              :rules="rules.required"
              :items="month"
            ></v-select>
          </div>
          <div>
            <v-row>
              <v-col :cols="9">
                <h4 class="text-8px text-header-field">年会費</h4>
                <v-text-field
                  dense
                  :reverse="true"
                  :type="statusAnnualFee ? 'number' : 'text'"
                  @focus="statusAnnualFee = true"
                  @blur="statusAnnualFee = false"
                  :rules="rules.required"
                  @change="changeAnnualFee($event)"
                  v-model="getAnnualFeeCustomerMc"
                ></v-text-field>
              </v-col>
              <v-col :cols="3">
                <h4 class="text-8px text-header-field">消費税</h4>
                <p class="mt-2 text-data-field">
                  {{
                    annualFeeTax
                      ? annualFeeTax.toLocaleString('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        })
                      : '¥0,00'
                  }}
                </p>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col :cols="9">
                <h4 class="text-8px text-header-field">メンバーシップフィー</h4>
                <v-select
                  dense
                  :items="itemMembershipFee"
                  item-value="id"
                  item-text="name"
                  :rules="rules.required"
                  v-model="getMembershipFeeCustomerMc"
                  @change="changeMemberShipFeeCustomerMc($event)"
                >
                </v-select>
              </v-col>
              <v-col :cols="3">
                <h4 class="text-8px text-header-field">消費税</h4>
                <p class="mt-2 text-data-field">
                  {{
                    memberShipFeeCustomerMc
                      ? memberShipFeeCustomerMc.toLocaleString('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        })
                      : '¥0,00'
                  }}
                </p>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col :cols="9">
                <h4 class="text-8px text-header-field">管理費</h4>
                <v-text-field
                  dense
                  :reverse="true"
                  :type="statusManagementFee ? 'number' : 'text'"
                  @focus="statusManagementFee = true"
                  @blur="statusManagementFee = false"
                  :rules="rules.required"
                  @change="changeManagementFeeTax($event)"
                  v-model="getManagementFeeCustomerMc"
                ></v-text-field>
              </v-col>
              <v-col :cols="3">
                <h4 class="text-8px text-header-field">消費税</h4>
                <p class="mt-2 text-data-field">
                  {{
                    managementFeeTax
                      ? managementFeeTax.toLocaleString('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        })
                      : '¥0,00'
                  }}
                </p>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col :cols="9">
                <h4 class="text-8px text-header-field">温泉維持費</h4>
                <v-text-field
                  dense
                  :reverse="true"
                  :type="statusHotspringFee ? 'number' : 'text'"
                  @focus="statusHotspringFee = true"
                  @blur="statusHotspringFee = false"
                  :rules="rules.required"
                  @change="changeHotspringFeeTax($event)"
                  v-model="getHotspringFeeCustomerMc"
                ></v-text-field>
              </v-col>
              <v-col :cols="3">
                <h4 class="text-8px text-header-field">消費税1</h4>
                <p class="mt-2 text-data-field">
                  {{
                    hotspringFeeTax
                      ? hotspringFeeTax.toLocaleString('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        })
                      : '¥0,00'
                  }}
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- if data.processedAt !== null -->

        <!-- End Content -->
        <div class="d-flex justify-end mt-2">
          <v-btn
            class="t-btn--red-dark btn-dialog mr-2"
            width="72"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm btn-dialog" width="68" @click="save">
            {{ $t('task_29786.keep') }}
          </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { SALES_TAX } from '@/api/graphql/customer/response/detail/membershipContract/CustomerMembershipContract/index.js';
import gql from 'graphql-tag';

export default {
  name: 'TicketsBuy',
  data() {
    return {
      memberShipFeeCustomerMc: null,
      itemMembershipFee: [
        { id: 0, name: '¥0' },
        { id: 24000, name: '¥24,000' },
      ],
      tax: 10,
      statusAnnualFee: false,
      statusManagementFee: false,
      statusHotspringFee: false,
      annualFeeTax: null,
      membershipFeeTax: 0,
      managementFeeTax: null,
      hotspringFeeTax: null,

      // Billing
      checkBilling: false,
      rules: {
        required: [(v) => !!v || '必須項目です。'],
      },
    };
  },
  props: {
    visible: Boolean,
    data: Object,
    defaultYearSelected: Number,
  },
  computed: {
    month() {
      var arr = [];
      for (let i = 1; i <= 12; i++) {
        arr.push({ id: i, name: i + '月' });
      }
      return arr;
    },
    ...mapGetters([
      'monthCustomerMc',
      'annualFeeCustomerMc',
      'annualFeeTaxCustomerMc',
      'membershipFeeCustomerMc',
      'membershipFeeTaxCustomerMc',
      'managementFeeCustomerMc',
      'managementFeeTaxCustomerMc',
      'hotspringFeeCustomerMc',
      'hotspringFeeTaxCustomerMc',
      'remarksCustomerMc',
    ]),
    getMonthCustomerMc: {
      get() {
        return this.monthCustomerMc;
      },
      set(value) {
        this.setMonthCustomerMc(value);
      },
    },
    getAnnualFeeCustomerMc: {
      get() {
        return this.formatMoney(this.statusAnnualFee, this.annualFeeCustomerMc);
      },
      set(value) {
        this.setAnnualFeeCustomerMc(value);
      },
    },

    getMembershipFeeCustomerMc: {
      get() {
        if (this.membershipFeeCustomerMc === 0)
          return { id: this.membershipFeeCustomerMc, name: '¥0' };
        else return { id: this.membershipFeeCustomerMc, name: '¥24,000の2択' };
      },
      set(value) {
        this.setMembershipFeeCustomerMc(value);
      },
    },

    getManagementFeeCustomerMc: {
      get() {
        return this.formatMoney(
          this.statusManagementFee,
          this.managementFeeCustomerMc,
        );
      },
      set(value) {
        this.setManagementFeeCustomerMc(value);
      },
    },

    getHotspringFeeCustomerMc: {
      get() {
        return this.formatMoney(
          this.statusHotspringFee,
          this.hotspringFeeCustomerMc,
        );
      },
      set(value) {
        this.setHotspringFeeCustomerMc(value);
      },
    },

    getRemarksCustomerMc: {
      get() {
        return this.remarksCustomerMc;
      },
      set(value) {
        this.setRemarksCustomerMc(value);
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'createannualfeeCustomerMc',
      //   'getClientAnnualfeeListCustomerMembershipContract'
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setMonthCustomerMc',
      'setAnnualFeeCustomerMc',
      'setAnnualFeeTaxCustomerMc',
      'setMembershipFeeCustomerMc',
      'setMembershipFeeTaxCustomerMc',
      'setManagementFeeCustomerMc',
      'setManagementFeeTaxCustomerMc',
      'setHotspringFeeCustomerMc',
      'setHotspringFeeTaxCustomerMc',
      'setRemarksCustomerMc',
      'setClientIdCustomerMc',
      'setDefaultYearSelected',
      'setAlertSuccess',
      'setPermissionUser',
      'setRoleAdminUser',
      'setAlertError',
    ]),

    async calculateSalesTax(price) {
      var getFullTime = null;
      if (this.getMonthCustomerMc === '' || this.getMonthCustomerMc === 0) {
        getFullTime = this.defaultYearSelected + '-' + '01-01';
      } else {
        if (this.getMonthCustomerMc > 9) {
          getFullTime =
            this.defaultYearSelected +
            '-' +
            this.getMonthCustomerMc +
            '-' +
            '01';
        } else
          getFullTime =
            this.defaultYearSelected +
            '-0' +
            this.getMonthCustomerMc +
            '-' +
            '01';
      }
      const variables = {
        price: Number(price),
        taxType: 'Normal',
        date: getFullTime,
      };
      return this.$apollo
        .query({
          query: gql`
            ${SALES_TAX}
          `,
          variables: variables,
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              SALES_TAX,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
          throw error
        });
    },


    async changeAnnualFee(e) {
      await this.calculateSalesTax(e)
        .then((data) => {
          this.annualFeeTax = data.data.salesTax.salesTax;
        })
    },

    async changeMemberShipFeeCustomerMc(e) {
      await this.calculateSalesTax(e)
        .then((data) => {
          this.memberShipFeeCustomerMc = data.data.salesTax.salesTax;
        })
    },

    async changeManagementFeeTax(e) {
      await this.calculateSalesTax(e)
        .then((data) => {
          this.managementFeeTax = data.data.salesTax.salesTax;
        })
    },

    async changeHotspringFeeTax(e) {
      await this.calculateSalesTax(e)
        .then((data) => {
          this.hotspringFeeTax = data.data.salesTax.salesTax;
        })
    },

    formatMoney(status, val) {
      if (val) {
        if (status === false) {
          if (val.length === 0) {
            val = 0;
          }
          return parseFloat(val).toLocaleString('ja-JP', {
            style: 'currency',
            currency: 'JPY',
          });
        }
        return val;
      } else {
        return null;
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.setDefaultYearSelected(this.defaultYearSelected);
        this.createannualfeeCustomerMc(this.$route.params.id);
        this.setAlertSuccess('年会費の追加に成功しました');
        this.closeDialog();
      }
    },
    closeDialog() {
      this.getRemarksCustomerMc = '';
      this.getMonthCustomerMc = '';
      this.getAnnualFeeCustomerMc = null;
      this.getMembershipFeeCustomerMc = null;
      this.getManagementFeeCustomerMc = null;
      this.getHotspringFeeCustomerMc = null;
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    changeStatus() {
      this.data.processedAt = new Date().toISOString().substr(0, 10);
    },
    calculateTax(value) {
      if (value) {
        return (value * this.tax) / 100;
      } else {
        return '¥00.00';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .v-text-field__slot {
    input {
      font-size: 12px;
      color: #444444 !important;
    }
  }
  .v-select__selection {
    font-size: 12px;
    color: #444444 !important;
  }
  .v-dialog {
    width: 350px;
    height: 620px;
    background-color: white;
    padding: 15px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
// ----
.text-header {
  font-size: 20px;
  color: #212121;
}
// div
.div-alert {
  color: #ffffff;
  width: 320px;
  margin-left: -6px;
  height: 46px;
  background-color: #cb396c;
  border-radius: 9px;
}
// icon
.icon-alert {
  font-size: 20px;
}
// Text
.text-alert {
  font-size: 14px;
}
.text-header-field {
  color: #000000;
}
.text-data-field {
  font-size: 12px;
  color: #444444;
}
// button
.btn-billing {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background-color: #cb396c !important;
  width: 73px;
  font-size: 12px !important;
  height: 32px !important;
}
.btn-dialog {
  height: 32px !important;
  font-size: 12px;
  color: #ffffff;
}
</style>
