<template>
  <v-dialog v-model="dialog">
    <h1>パスワード設定リンクを送信しました</h1>
    <div class="d-flex justify-end mt-7">
      <v-btn class="t-btn--red-dark" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
        閉じる
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SettingLink',
  data() {
    return {
      valid: false,
      row: null,

      isShowDate: false,
      dates: [],
    };
  },
  props: {
    visible: Boolean,
    showLink: Boolean,
  },
  computed: {
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ');
      },
      set() {
        this.dates.join(' ~ ');
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
.v-btn {
  font-size: 12px !important;
  width: 72px !important;
  height: 33px !important;
  i {
    font-size: 14px;
  }
}
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 512px;
    padding: 17px;
    background-color: #fff !important;
    border-radius: 9px;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
}
</style>