<template>
  <div>
    <v-dialog @click:outside="closeDialog" v-model="dialog">
      <h1>TVP追加/利用・取り消し</h1>
      <div class="mt-5">
        <h5>分類</h5>
        <template>
          <v-select
            class="w-50"
            v-model="typeNumberSelected"
            :items="['追加', '利用・取り消し']"
          ></v-select>
        </template>
      </div>
      <v-form ref="form">
        <div>
          <h5>TVP数</h5>
          <v-text-field
            class="w-50"
            type="number"
            :rules="rules.required"
            v-model="pointChange"
          ></v-text-field>
        </div>
        <div>
          <h5>備考</h5>
          <v-text-field :rules="rules.required" v-model="remarks"></v-text-field>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm" @click="actionCreate()">
            {{ $t('task_29786.keep') }}
          </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AddUseCancel',
  data() {
    return {
      remarks: '',
      pointChange: '',
      numberSending: '',
      dataGroup: [],
      typeNumberSelected: '追加',
      rules: {
        required: [v => !!v || '必須項目です。'],
      },
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters([
      'getChangeTVP',
      'getClientId',
      'getPointChange',
      'getRemarksAddNew',
    ]),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions(['changeTVP']),
    ...mapMutations([
      'setChangeTVP',
      'setPointChange',
      'setRemarks',
      'setAlertSuccess',
    ]),

    actionCreate() {
      if (this.$refs.form.validate()) {
        this.numberSending =
          this.typeNumberSelected === '追加'
            ? this.pointChange
            : '-' + this.pointChange;
        this.$emit('close');
        const addUseCancel = {
          clientId: parseInt(this.$route.params.id),
          remarks: this.remarks,
          pointChange: parseInt(this.numberSending),
        };
        this.setChangeTVP({ addUseCancel: addUseCancel });
        this.closeDialog();
        this.changeTVP();
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}

h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
h5 {
  font-size: 12px !important;
  color: #3083dc;
}

::v-deep {
  .v-dialog {
    width: 360px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .v-select__selections {
    .v-select__selection--comma {
      color: #000;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .v-list-item__content {
    .v-list-item__title {
      font-size: 12px !important;
      font-weight: 600;
    }
  }
  .v-text-field {
    margin-top: 0 !important;
  }
  .w-50 {
    width: 50%;
  }
  .v-input__slot {
    input {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .v-counter {
    font-size: 12px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .v-input__slot {
    border-bottom: 1px solid #3083dc !important;
  }
  .v-icon__svg {
    color: #333 !important;
  }
  .v-btn {
    font-size: 12px;
    font-weight: 500;
    height: 33px !important;
  }
  .t-btn--red-dark {
    width: 72px !important;
    i {
      font-size: 14px;
    }
  }
  .t-btn--prm {
    width: 68px !important;
  }
}
</style>
