<template>
  <div>
    <customer-details>
      <template v-slot:btnPrope>
        <v-btn
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
          @click="saveData"
        >
          保存
        </v-btn>
      </template>
      <div class="mx-5">
        <div class="mt-5">
          <div class="d-flex justify-space-between">
            <h2>
              {{ $t('task_29786.CustomerMembership.contract') }}
            </h2>
            <div>
              <v-btn
                :disabled="!checkPerUser"
                class="t-btn--prm mx-5 mt-4 vs-vm-button"
                @click="setDialog(2)"
              >
                <span>
                  <i class="fas fa-plus" style="margin-right: 10px"></i
                  >VS契約作成</span
                >
              </v-btn>
              <v-btn
                :disabled="!checkPerUser"
                class="t-btn--prm mt-4 vs-vm-button"
                @click="setDialog(1)"
                active-class="no-active"
              >
                <span>
                  <i class="fas fa-plus" style="margin-right: 10px"></i
                  >VM契約作成</span
                >
              </v-btn>
            </div>
          </div>
          <v-card>
            <!-- class="table1" -->
            <Table
              :attr="{
                dense: true,
                'no-data-text': $t('rules.noData'),
                'item-key': 'id',
                height:
                  contractlistCustomerMembershipContract &&
                  contractlistCustomerMembershipContract.length >= 10
                    ? '500'
                    : '',
              }"
              ref="table"
              :itemsPerPage="30"
              :itemsPerPageOptions="[30, 60, 120, 480]"
              :headers="headerContract"
              :items="contractlistCustomerMembershipContract"
            >
              <template v-slot:[`item.productTypeName`]="{ item }">
                {{ item.productType.code }}
              </template>
              <template v-slot:[`item.contractNumber`]="{ item }">
                {{ item.contractNumber ? item.contractNumber : '' }}
              </template>
              <template v-slot:[`item.phase`]="{ item }">
                {{ item.phase ? item.phase.name : '' }}
              </template>
              <template v-slot:[`item.startDate`]="{ item }">
                {{
                  item.startDate ? item.startDate.toString().substr(0, 4) : ''
                }}
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                {{ item.endDate ? item.endDate.toString().substr(0, 4) : '' }}
              </template>
              <template v-slot:[`item.edit`]="{ item }">
                <v-btn icon :disabled="!checkPerUser" @click="getUrl(item)">
                  <v-icon
                    x-large
                    color="#3AC1EE"
                    style="box-shadow: 0px 3px 6px #00000029"
                  >
                    mdi-pencil-circle</v-icon
                  >
                </v-btn>
              </template>
            </Table>
          </v-card>
        </div>

        <div class="mt-10 mb-16">
          <h2 class="mb-5">会員情報</h2>
          <v-card outlined>
            <div class="divMemberInformation pt-8">
              <div class="mb-3">
                <h6 class="t-10-c-black">
                  {{ $t('task_29786.CustomerMembership.MembershipNumber') }}
                </h6>
                <span>{{ memberId }}</span>
              </div>
              <h6 class="t-10-c-black">
                {{ $t('task_29786.CustomerMembership.EmailAddress') }}
              </h6>
              <div class="d-flex" style="width: 50%">
                <v-text-field
                  @input="changeEmail($event)"
                  :rules="[...rules.rulesEmail, memberUserEmailCannotBeRemoved]"
                  @blur="restoreOriginalEmailIfNeeded"
                  dense
                  v-model="memberUserEmail"
                >
                </v-text-field>
                <v-btn
                  class="btn-crm-primary ml-4 send-password-setting-link"
                  @click="visible = 4"
                  :disabled="!checkPerUser"
                >
                  {{ $t('task_29786.CustomerMembership.PasswordSettingLink') }}
                </v-btn>
              </div>

              <!-- checkbox -->
              <div
                class="d-flex justify-space-between"
                style="width: 20%; margin-top: 10px; margin-bottom: 15px"
              >
                <label class="cbox">
                  <input
                    type="checkbox"
                    name="checkbox"
                    v-model="requestMemberMagazineMail"
                  />
                  <span class="check-box-text">会報誌送付希望</span></label
                >
                <label class="cbox">
                  <input
                    type="checkbox"
                    name="checkbox"
                    v-model="requestBrochureMail"
                  />
                  <span class="check-box-text">利用ガイド送付希望</span></label
                >
              </div>
              <!-- end checkbox -->

              <!-- 法人会員支払い方法 -->
              <div class="mb-3" v-if="data.isCompany">
                <h3>{{ $t('task_29786.CustomerMembership.CorporateMemberPaymentMethod')}}</h3>
                <div class="d-flex mt-3">
                  <div class="selectCustom">
                    <h6 class="t-10-c-black">
                      {{ $t('task_29786.CustomerMembership.AccommodationFee') }}
                    </h6>
                    <template>
                      <v-select
                        dense
                        :items="items"
                        item-text="name"
                        item-value="id"
                        v-model="accommodationFeePayment"
                      ></v-select>
                    </template>
                  </div>
                  <div class="selectCustom">
                    <h6 class="t-10-c-black">
                      {{ $t('task_29786.CustomerMembership.UsageFee') }}
                    </h6>
                    <template>
                      <v-select
                        dense
                        :items="items"
                        item-text="name"
                        item-value="id"
                        v-model="facilityFeePayment"
                      ></v-select>
                    </template>
                  </div>
                  <div class="selectCustom">
                    <h6 class="t-10-c-black">
                      {{ $t('task_29786.CustomerMembership.SetupFee') }}
                    </h6>
                    <template>
                      <v-select
                        dense
                        :items="items"
                        item-text="name"
                        item-value="id"
                        v-model="setupFeePayment"
                      ></v-select>
                    </template>
                  </div>
                  <div class="selectCustom">
                    <h6 class="t-10-c-black">
                      {{
                        $t('task_29786.CustomerMembership.OptionalCleaningFee')
                      }}
                    </h6>
                    <template>
                      <v-select
                        dense
                        :items="items"
                        item-text="name"
                        item-value="id"
                        v-model="optionalCleaningPayment"
                      ></v-select>
                    </template>
                  </div>
                  <div class="selectCustom">
                    <h6 class="t-10-c-black">
                      {{ $t('task_29786.CustomerMembership.BathTax') }}・{{
                        $t('task_29786.CustomerMembership.OccupancyTax')
                      }}・{{
                        $t('task_29786.CustomerMembership.HotSpringMaintenanceFee')
                      }}
                    </h6>
                    <template>
                      <v-select
                        dense
                        :items="items"
                        item-text="name"
                        item-value="id"
                        v-model="taxPayment"
                      ></v-select>
                    </template>
                  </div>
                  <div class="selectCustom">
                    <h6 class="t-10-c-black">有料サービス</h6>
                    <template>
                      <v-select
                        dense
                        :items="items"
                        item-text="name"
                        item-value="id"
                        v-model="otherServicePayment"
                      ></v-select>
                    </template>
                  </div>
                </div>
              </div>
              <!-- end 法人会員支払い方法 -->

              <!-- 副会員 -->
              <template>
                <div class="d-flex justify-space-between mt-8 mb-2">
                  <h3 class="mb-2">副会員</h3>
                  <v-btn
                    class="btn-crm-primary"
                    @click="dialogCreateSubType(5, '副会員')"
                    style="margin-right: 20px"
                    :disabled="!checkPerUser"
                    v-if="dataSubmember.length === 0"
                  >
                    <span><i class="fas fa-plus"></i>&nbsp;副会員を追加</span>
                  </v-btn>
                </div>
                <!-- @click="onExpansionPanelClick(item, $event)" -->
                <div class="mb-10" style="margin-left: -20px">
                  <v-card
                    class="mb-5"
                    v-for="(item, i) in dataSubmember"
                    :key="i"
                  >
                    <div>
                      <div class="px-5 py-5">
                        <!-- <div class="d-flex justify-space-between"> -->
                        <v-row class="d-flex justify-space-between">
                          <v-col cols="5">
                            <div style="">
                              <div>
                                <h6 class="t-10-c-black">会員名</h6>
                                <v-text-field v-model="item.name">
                                </v-text-field>
                              </div>
                              <div class="mt-1">
                                <h6 class="t-10-c-black">フリガナ</h6>
                                <v-text-field v-model="item.nameKana">
                                </v-text-field>
                              </div>
                              <div class="mt-5">
                                <v-row>
                                  <v-col
                                    :cols="3"
                                    style="padding-top: 0 !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t(
                                          'task_29786.CustomerMembership.Birthday',
                                        )
                                      }}
                                    </h6>
                                    <v-menu
                                      ref="menu"
                                      v-model="menuBirthDay1[i]"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="item.birthday"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          dense
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="item.birthday"
                                        :max="
                                          new Date(
                                            Date.now() -
                                              new Date().getTimezoneOffset() *
                                                60000,
                                          )
                                            .toISOString()
                                            .substr(0, 10)
                                        "
                                        :locale="$i18n.locale"
                                        min="1900-01-01"
                                        @change="saveBirthDay"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col
                                    :cols="2"
                                    style="padding-top: 0 !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t('task_29786.CustomerMembership.age')
                                      }}
                                    </h6>
                                    <p style="margin-top: 7px">
                                      {{
                                        item.birthday
                                          ? getAgeFromDate(item.birthday) + '歳'
                                          : ''
                                      }}
                                    </p>
                                  </v-col>
                                  <v-col
                                    :cols="7"
                                    style="padding-top: 0 !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t(
                                          'task_29786.CustomerMembership.relationship',
                                        )
                                      }}
                                    </h6>
                                    <template>
                                      <v-select
                                        dense
                                        :items="
                                          enumPersonrelationshipListCustomerMc
                                        "
                                        v-model="item.relationship"
                                        item-text="name"
                                        item-value="id"
                                      ></v-select>
                                    </template>
                                  </v-col>
                                </v-row>
                              </div>
                              <div>
                                <h6
                                  class="t-10-c-black"
                                  style="margin-bottom: -7px"
                                >
                                  {{ $t('task_29786.CustomerMembership.sex') }}
                                </h6>
                                <v-select
                                  style="width: 21%"
                                  :items="itemGender"
                                  v-model="item.sex"
                                  item-text="name"
                                  item-value="id"
                                ></v-select>
                              </div>
                              <div>
                                <h6 class="t-10-c-black">
                                  ログインメールアドレス
                                </h6>
                                <v-text-field
                                  :rules="[...rules.rulesEmail, subMemberUserEmailCannotBeRemoved(item)]"
                                  :value="
                                    item.memberUser
                                      ? item.memberUser.email
                                      : null
                                  "
                                  @input="setLoginEmail(item, $event)"
                                  @blur="restoreOriginalSubMemberEmailIfNeeded(item)"
                                >
                                </v-text-field>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="5">
                            <div style="">
                              <div style="margin-top: 16px">
                                <v-text-field
                                  style="width: 50%"
                                  label="郵便番号"
                                  v-model="item.postalCode"
                                  :rules="[$rules.postalCodeNoHyphen]"
                                  class="t-12-c-4x6 mt-1"
                                >
                                </v-text-field>
                              </div>
                              <div class="mt-3">
                                <v-row style="margin-top: -27px !important">
                                  <v-col :cols="6" class="mt-5">
                                    <template>
                                      <v-select
                                        label="都道府県"
                                        dense
                                        :items="enumPrefectureListCustomerMc"
                                        item-text="name"
                                        item-value="name"
                                        v-model="item.prefecture"
                                        class="t-12-c-4x6"
                                      ></v-select>
                                    </template>
                                  </v-col>
                                  <v-col :cols="6">
                                    <template>
                                      <label>市区郡</label>
                                      <v-text-field
                                        dense
                                        :items="
                                          item.locality ? item.locality : ''
                                        "
                                        v-model="item.locality"
                                        class="t-12-c-4x6 top-text"
                                      ></v-text-field>
                                    </template>
                                  </v-col>
                                </v-row>
                              </div>
                              <div class="mt-3">
                                <v-text-field
                                  label="町名・番地"
                                  class="t-12-c-4x6"
                                  v-model="item.address1"
                                  style="padding: 8px 0 0 0 !important"
                                >
                                </v-text-field>
                              </div>
                              <div class="mt-3">
                                <v-text-field
                                  label="ビル名、マンション名等"
                                  class="t-12-c-4x6"
                                  v-model="item.address2"
                                >
                                </v-text-field>
                              </div>
                              <div class="mt-3">
                                <v-text-field
                                  label="電話番号"
                                  v-model="item.tel"
                                  :value="item.tel ? item.tel : ''"
                                  class="t-12-c-4x6"
                                  style="
                                    padding: 0 !important ;
                                    margin-top: 0 !important;
                                  "
                                >
                                </v-text-field>
                              </div>
                              <div class="mt-3">
                                <v-text-field
                                  label="メールアドレス"
                                  class="t-12-c-4x6"
                                  v-model="item.email"
                                  :rules="rules.rulesEmail"
                                  :value="item.email ? item.email : ''"
                                  style="
                                    padding: 0 !important ;
                                    margin-top: 0 !important;
                                  "
                                >
                                </v-text-field>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <!-- </div> -->
                        <div>
                          <h6 class="t-10-c-black" style="margin-bottom: 7px">
                            {{
                              $t(
                                'task_29786.CustomerMembership.ViceMemberRemarks',
                              )
                            }}
                          </h6>
                          <v-textarea
                            auto-grow
                            outlined
                            rows="3"
                            v-model="item.remarks"
                            :value="item.remarks ? item.remarks : ''"
                            row-height="30"
                            :rules="[$rules.checkTextAreaLength()]"
                          ></v-textarea>
                        </div>

                        <div>
                          <v-btn
                            class="
                              t-btn--red-dark
                              mt-2
                              button-delete-family-members
                            "
                            :disabled="!checkPerUser"
                            @click="deleteFamilyMember(item)"
                          >
                            {{
                              $t(
                                'task_29786.CustomerMembership.DeleteViceMembers',
                              )
                            }}
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </template>
              <!-- end 副会員 -->

              <!-- ファミリー会員 -->
              <template>
                <div class="d-flex justify-space-between mt-8 mb-2">
                  <h3>ファミリー会員</h3>
                  <v-btn
                    class="btn-crm-primary button-add-family-members"
                    @click="dialogCreateSubType(5, 'ファミリー会員')"
                    style="margin-right: 20px"
                    :disabled="!checkPerUser"
                  >
                    <span
                      ><i class="fas fa-plus"></i>&nbsp;{{
                        $t('task_29786.CustomerMembership.AddFamilyMembers')
                      }}</span
                    >
                  </v-btn>
                </div>
                <v-expansion-panels
                  class="mb-10"
                  style="margin-left: -20px"
                  multiple
                >
                  <v-expansion-panel v-for="(item, i) in dataFamily" :key="i">
                    <v-expansion-panel-header>
                      <div class="d-flex justify-stard">
                        <h4 class="expan-title">
                          <span class="mr-7" v-if="item.name">
                            {{ item.name }}
                          </span>
                          <span v-if="item.relationship">
                            続柄 : {{ item.relationship.name }}
                          </span>
                        </h4>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card class="px-5 py-5">
                        <!-- <div class="d-flex justify-space-between"> -->
                        <v-row class="d-flex justify-space-between">
                          <v-col cols="5">
                            <div style="">
                              <div>
                                <div class="d-flex align-center">
                                  <h6 class="t-10-c-black sub-member-name">会員名</h6>
                                  <v-btn
                                    class="t-btn--red-dark ml-2 button-send-email"
                                    :disabled="!checkPerUser"
                                    @click="sendRegistrationEmail(item)"
                                  >
                                    登録完了メールを送信
                                  </v-btn>
                                  <div class="ml-2">
                                    会員情報を入力後、保存ボタンをクリックしてからメールを送信してください。
                                  </div>
                                </div>
                                <v-text-field v-model="item.name">
                                </v-text-field>
                              </div>
                              <div class="mt-1">
                                <h6 class="t-10-c-black">フリガナ</h6>
                                <v-text-field v-model="item.nameKana">
                                </v-text-field>
                              </div>
                              <div class="mt-5">
                                <v-row>
                                  <v-col
                                    :cols="3"
                                    style="padding-top: 0px !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t(
                                          'task_29786.CustomerMembership.Birthday',
                                        )
                                      }}
                                    </h6>
                                    <v-menu
                                      ref="menu2"
                                      v-model="menuBirthDay2[i]"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="item.birthday"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          dense
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="item.birthday"
                                        :max="
                                          new Date(
                                            Date.now() -
                                              new Date().getTimezoneOffset() *
                                                60000,
                                          )
                                            .toISOString()
                                            .substr(0, 10)
                                        "
                                        :locale="$i18n.locale"
                                        min="1900-01-01"
                                        @change="saveBirthDay2"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col
                                    :cols="2"
                                    style="padding-top: 0px !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t('task_29786.CustomerMembership.age')
                                      }}
                                    </h6>
                                    <p style="margin-top: 7px">
                                      {{
                                        item.birthday
                                          ? getAgeFromDate(item.birthday) + '歳'
                                          : ''
                                      }}
                                    </p>
                                  </v-col>
                                  <v-col
                                    :cols="3"
                                    style="padding-top: 0px !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t(
                                          'task_29786.CustomerMembership.relationship',
                                        )
                                      }}
                                    </h6>
                                    <template>
                                      <v-select
                                        dense
                                        :items="
                                          enumPersonrelationshipListCustomerMc
                                        "
                                        v-model="item.relationship"
                                        item-text="name"
                                        item-value="id"
                                      ></v-select>
                                    </template>
                                  </v-col>
                                  <v-col
                                    :cols="4"
                                    style="padding-top: 0px !important"
                                  >
                                    <h6 class="t-10-c-black">
                                      {{
                                        $t('task_29786.CustomerMembership.sex')
                                      }}
                                    </h6>
                                    <v-select
                                      style="width: 50%"
                                      dense
                                      :items="itemGender"
                                      v-model="item.sex"
                                      item-text="name"
                                      item-value="id"
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </div>
                              <div class="mt-1"></div>
                              <div>
                                <h6 class="t-10-c-black">
                                  ログインメールアドレス
                                </h6>
                                <v-text-field
                                  dense
                                  :rules="[...rules.rulesEmail, subMemberUserEmailCannotBeRemoved(item)]"
                                  :value="
                                    item.memberUser
                                      ? item.memberUser.email
                                      : null
                                  "
                                  @input="setLoginEmail(item, $event)"
                                  @blur="restoreOriginalSubMemberEmailIfNeeded(item)"
                                >
                                </v-text-field>
                              </div>
                              <div class="d-flex mt-2">
                                <v-btn
                                  class="t-btn--red-dark button-delete-family-members"
                                  :disabled="!checkPerUser"
                                  @click="deleteFamilyMember(item)"
                                >
                                  {{
                                    $t(
                                      'task_29786.CustomerMembership.DeleteFamilyMembers',
                                    )
                                  }}
                                </v-btn>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="5">
                            <div style="">
                              <div style="margin-top: 16px">
                                <v-text-field
                                  dense
                                  style="width: 50%"
                                  label="郵便番号"
                                  v-model="item.postalCode"
                                  :rules="[$rules.postalCodeNoHyphen]"
                                  class="t-12-c-4x6 mt-1"
                                >
                                </v-text-field>
                              </div>
                              <div>
                                <v-row>
                                  <v-col
                                    :cols="6"
                                    style="padding: 12px 0 0 12px !important"
                                  >
                                    <template>
                                      <v-select
                                        label="都道府県"
                                        :items="enumPrefectureListCustomerMc"
                                        item-text="name"
                                        item-value="name"
                                        v-model="item.prefecture"
                                        class="t-12-c-4x6"
                                      ></v-select>
                                    </template>
                                  </v-col>
                                  <v-col :cols="6">
                                    <template>
                                      <v-text-field
                                        label="市区郡"
                                        :items="
                                          item.locality ? item.locality : ''
                                        "
                                        v-model="item.locality"
                                        class="t-12-c-4x6"
                                      ></v-text-field>
                                    </template>
                                  </v-col>
                                </v-row>
                              </div>
                              <div>
                                <v-text-field
                                  label="町名・番地"
                                  class="t-12-c-4x6"
                                  v-model="item.address1"
                                  style="padding: 8px 0 0 0 !important"
                                >
                                </v-text-field>
                              </div>
                              <div>
                                <v-text-field
                                  label="ビル名、マンション名等"
                                  class="t-12-c-4x6"
                                  v-model="item.address2"
                                  style="
                                    padding: 0 !important ;
                                    margin-top: 0 !important;
                                  "
                                >
                                </v-text-field>
                              </div>
                              <div>
                                <v-text-field
                                  label="電話番号"
                                  v-model="item.tel"
                                  :value="item.tel ? item.tel : ''"
                                  class="t-12-c-4x6"
                                >
                                </v-text-field>
                              </div>
                              <div>
                                <v-text-field
                                  label="メールアドレス"
                                  class="t-12-c-4x6"
                                  v-model="item.email"
                                  :rules="rules.rulesEmail"
                                  :value="item.email ? item.email : ''"
                                  style="
                                    padding: 0 !important ;
                                    margin-top: 0 !important;
                                  "
                                >
                                </v-text-field>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <!-- </div> -->
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <!-- end ファミリー会員 -->
            </div>
          </v-card>
        </div>
        <!-- end 会員情報 -->

        <!-- 東急バケーションズポイント -->
        <div class="mt-10">
          <!-- buttion -->
          <h2 class="mb-5 color-blue--drank">
            {{ $t('task_29786.CustomerMembership.TokyuVacationsPoint') }}
          </h2>
          <v-row class="my-3">
            <h4 class="mt-2 mx-3 color-bl">
              保有ポイント :
              {{
                tvphistoryListCustomerMc[0]
                  ? tvphistoryListCustomerMc[0].pointsAfter
                  : 0
              }}
            </h4>
            <div>
              <div>
                <v-btn
                  class="t-btn--prm mx-1"
                  @click="showDialog(1)"
                  :disabled="
                    this.tvphistoryListCustomerMc.length <= 0 || !checkPerUser
                  "
                >
                  <span>ギフトチケット購入</span>
                </v-btn>
                <v-btn
                  class="t-btn--prm mx-1"
                  @click="showDialog(2)"
                  :disabled="!checkPerUser"
                >
                  <span>追加/利用・取り消し</span>
                </v-btn>
                <v-btn class="t-btn--prm mx-1" @click="showDialog(7)">
                  <span>提携施設予約作成</span>
                </v-btn>
              </div>
            </div>
          </v-row>
          <!-- end buttion -->

          <!-- Table 東急バケーションズポイント -->
          <v-card class="mt-4">
            <Table
              :attr="{
                dense: true,
                'no-data-text': $t('rules.noData'),
                'item-key': 'id',
                class: 'table-header-vacations-point',
              }"
              ref="tableTvphistoryListCustomerMc"
              :itemsPerPage="10"
              :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
              :headers="headerTvphistoryListCustomerMc"
              :total="totalCount"
              :items="tvphistoryListCustomerMc"
              :funReset="loadList"
              :disableSort="true"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatDateTimeISONoSecond }}
              </template>
              <template v-slot:[`item.pointChange`]="{ item }">
                <span
                  :class="item.pointChange < 0 ? 't14-cdb3069' : 't14-c3083dc'"
                  >{{ item.pointChange | toThousands }}</span
                >
              </template>
              <template v-slot:[`item.stayDateRange`]="{ item }">
                <div v-if="item.checkInDate">
                {{ item.checkInDate | formatDate }} ~ {{
                  item.checkOutDate | formatDate
                }}
                </div>
              </template>
            </Table>
          </v-card>
          <!-- end Table 東急バケーションズポイント -->
        </div>
        <!-- End 東急バケーションズポイント -->

        <!-- Gift ticket order confirmation list -->
        <div class="mt-10">
          <h2 class="mb-5 color-bl--ob">
            {{
              $t(
                'task_29786.CustomerMembership.GiftTicketOrderConfirmationList',
              )
            }}
          </h2>
          <v-card>
            <Table
              :attr="{
                dense: true,
                'no-data-text': $t('rules.noData'),
                'item-key': 'id',
              }"
              ref="tablePointexchangelistCustomerMc"
              :itemsPerPage="10"
              :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
              :headers="headerPointexchangelistCustomerMc"
              :total="getTotalPointexchangelistCustomerMc"
              :items="pointexchangelistCustomerMc"
              :funReset="loadDataPointexchangelistCustomerMc"
              :disableSort="true"
            >
              <template v-slot:[`item.pointExchangeProductName`]="{ item }">
                {{ item.pointExchangeProduct.name }}
              </template>
              <template v-slot:[`item.pointExchangeName`]="{item}">
                {{ item.name }}
              </template>
              <template v-slot:[`item.confirmedAt`]="{ item }">
                {{
                  item.confirmedAt ? 'チケット発行会社メール開封確認' : '未開封'
                }}
              </template>
              <template v-slot:[`item.emailHistory`]="{ item }">
                <span v-if="item.emailHistory === null"> 未送信 </span>
                <span v-else>
                  {{ item.emailHistory.sentAt | formatDateTimeISONoSecond }}
                </span>
              </template>
              <template v-slot:[`item.edit`]="{}">
                <v-btn class="btn-crm-primary" small :disabled="!checkPerUser">
                  {{ $t('task_29786.edit') }}
                </v-btn>
              </template>
            </Table>
          </v-card>
        </div>
        <!-- End Gift ticket order confirmation list -->

        <!-- Annual fee -->
        <div class="my-10">
          <div class="d-flex justify-space-between mb-5">
            <div class="d-flex justify-space-between">
              <h2>
                {{ $t('task_29786.CustomerMembership.AnnualFee') }}
              </h2>
              <v-btn
                dense
                @click="setDialog(0)"
                :disabled="
                  selected.length === 0 ||
                    (!checkPerUser && selected.length !== 0)
                "
                class="t-btn--red-dark mx-3 delete-selected-year-button"
                >選択した年を削除
              </v-btn>
            </div>

            <div class="d-flex justify-space-between">
              <v-select
                v-model="defaultYearSelected"
                :items="years"
                item-text="name"
                item-value="id"
                label="年"
                outlined
                class="select-year"
              ></v-select>
              <v-btn
                @click="(visible = 6), (idx += 1)"
                :disabled="
                  defaultYearSelected === null ||
                    (!checkPerUser && defaultYearSelected !== null)
                "
                class="t-btn--prm add-selected-year-button"
                >選択した年を追加
              </v-btn>
            </div>
          </div>

          <v-card>
            <Table
              :attr="{
                dense: true,
                'no-data-text': $t('rules.noData'),
                'item-key': 'id',
                class: 'annual-fee-table',
                'disable-select-all': 'true',
              }"
              ref="tableClientAnnualFeeList"
              :itemsPerPage="10"
              :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
              :headers="headerClientAnnualFeeList"
              :total="getTotalClientAnnualfeeListCustomerMembershipContract"
              :showSelect="true"
              @setSelected="
                value => {
                  selected = value;
                }
              "
              :items="clientAnnualfeeListCustomerMembershipContract"
              :funReset="loadDataAnnualFee"
              :disableSort="true"
            >
              <template
                v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  :value="item.processedAt === null && isSelected"
                  :disabled="item.processedAt !== null"
                  :ripple="false"
                  @input="select($event)"
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.year`]="{ item }">
                {{ item.year }}
              </template>
              <template v-slot:[`item.month`]="{ item }">
                {{ item.month }}月
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span>{{ item.processedAt ? '請求作成済み' : '未請求' }}</span>
              </template>
              <template v-slot:[`item.processedAt`]="{ item }">
                {{
                  item.processedAt
                    ? new Date(item.processedAt).toISOString().substr(0, 10)
                    : ''
                }}
              </template>
              <template v-slot:[`item.remarks`]="{ item }">
                {{ item.remarks ? item.remarks : '' }}
              </template>
              <template v-slot:[`item.annualFee`]="{ item }">
                {{
                  parseFloat(item.annualFee).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.annualFeeTax`]="{ item }">
                {{
                  parseFloat(item.annualFeeTax).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.membershipFee`]="{ item }">
                {{
                  parseFloat(item.membershipFee).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.membershipFeeTax`]="{ item }">
                {{
                  parseFloat(item.membershipFeeTax).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.managementFee`]="{ item }">
                {{
                  parseFloat(item.managementFee).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.managementFeeTax`]="{ item }">
                {{
                  parseFloat(item.managementFeeTax).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.hotspringFee`]="{ item }">
                {{
                  parseFloat(item.hotspringFee).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.hotspringFeeTax`]="{ item }">
                {{
                  parseFloat(item.hotspringFeeTax).toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  })
                }}
              </template>
              <template v-slot:[`item.edit`]="{ item }">
                <v-btn
                  icon
                  v-if="item.processedAt === null"
                  @click="showDialogChangeAnnualFee(item)"
                  :disabled="!checkPerUser"
                >
                  <v-icon
                    x-large
                    color="#3AC1EE"
                    style="box-shadow: 0px 3px 6px #00000029"
                  >
                    mdi-pencil-circle</v-icon
                  >
                </v-btn>
              </template>
            </Table>
          </v-card>
        </div>
        <!-- End Annual fee -->
        <PointExchangeOrderDialog
          :value="visible === 1"
          :point="pointsAfter"
          :clientId="clientId"
          priceType="tvpPoint"
          @input="visible = -1"
        />
        <change-member
          :key="idxEdit"
          v-if="visible === 3"
          :visible="visible === 3"
          @close="visible = -1"
          @resetNotClose="() => { this.$refs.tableClientAnnualFeeList.reset() }"
          :data="data"
        />
        <add-use-cancel
          :visible="visible === 2"
          @close="visible = -1"
          @clearValidate="clearValidate()"
        />
        <setting-link
          :objResetEmail="objResetEmail"
          :visible="visible === 4"
          @close="visible = -1"
        ></setting-link>
        <add-member
          :subMemberTypename="subType"
          :visible="visible === 5"
          @close="visible = -1"
          @addFalseToMenuBirthDay1="menuBirthDay1.push(false)"
          @addFalseToMenuBirthDay2="menuBirthDay2.push(false)"
        />
        <create-annual-fee
          :key="idx"
          :visible="visible === 6"
          :defaultYearSelected="defaultYearSelected"
          @close="visible = -1"
        ></create-annual-fee>

        <point-affiliated-facility-reservation
          :visible="visible === 7"
          :id="dataSendPMS.id"
          type="TVP"
          @close="visible = -1"
          :clientId="parseInt($route.params.id)"
        ></point-affiliated-facility-reservation>

        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="310">
            <v-card>
              <v-card-title class="text-h5">
                {{ textDialogAlert }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="t-btn--red-dark" text @click="dialog = false">
                  いいえ
                </v-btn>
                <v-btn
                  class="file t-btn--prm"
                  style="color: white"
                  :to="linkDialogAlert"
                  @click="runFunc(functionHandle)"
                  target="_blank"
                  :disabled="!checkPerUser"
                >
                  はい
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </customer-details>
  </div>
</template>

<script>
import AddUseCancel from './AddUseCancel.vue';
import CustomerDetails from '../CustomerDetails.vue';
import SettingLink from './settingLink.vue';
import ChangeMember from './ChangeMember.vue';
import { getAgeFromDate } from '@/utils/dateUtil';
import CreateAnnualFee from './createAnnualFee.vue';
import AddMember from './AddMember.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { isEmailValid } from '@/utils/validate.js';
import PointAffiliatedFacilityReservation from '@/views/contract/contractDetail/Sp_Ep/UsageSituation/PointAffiliatedFacilityReservation.vue';
import Table from '@/components/Table/index.vue';
import PointExchangeOrderDialog from '@/components/dialog/PointExchangeOrderDialog.vue';

export default {
  components: {
    PointExchangeOrderDialog,
    AddUseCancel,
    ChangeMember,
    SettingLink,
    CustomerDetails,
    AddMember,
    CreateAnnualFee,
    PointAffiliatedFacilityReservation,
    Table,
  },
  name: 'customer-detail-membership-contract',

  data() {
    return {
      dataSendPMS: {},
      dataSubmember: [],
      dataFamily: [],
      objResetEmail: {},
      idxEdit: 0,
      idx: 0,
      menuBirthDay1: [],
      menuBirthDay2: [],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      textDialogAlert: '',
      linkDialogAlert: '',
      functionHandle: null,
      rules: {
        rulesEmail: [
          v => !v || isEmailValid(v) || this.$t('rules.emailIsInvalid'),
        ],
        number: [v => !v > 0 || '数値は0より大きくなければなりません'],
        required: [v => !!v || '必須項目です。'],
      },
      dialog: false,
      items: [
        { id: 'Onsite', name: '現地施設' },
        { id: 'RegisteredCC', name: '登録カード' },
      ],
      itemGender: [
        { id: 'Male', name: '男性' },
        { id: 'Female', name: '女性' },
        { id: 'Other', name: 'その他' },
      ],
      data: {},
      pointsAfter: 0,
      visible: -1,
      headerContract: [
        {
          text: 'ステータス',
          value: 'status',
          width: '120px',
        },
        {
          text: '契約商品',
          value: 'productTypeName',
          width: '50px',
        },
        {
          text: '施設',
          value: 'computedFacility.facilityName',
          width: '200px',
        },
        {
          text: '契約番号',
          value: 'contractNumber',
          width: '200px',
        },
        {
          text: 'フェーズ',
          value: 'phase',
          width: '120px',
        },
        {
          text: '契約開始年',
          value: 'startDate',
          width: '120px',
        },
        {
          text: '契約終了年',
          value: 'endDate',
          width: '120px',
        },
        {
          text: '契約詳細',
          value: 'edit',
          align: 'right',
          width: '100px',
          sortable: false,
        },
      ],
      headerPointexchangelistCustomerMc: [
        {
          text: this.$t('task_29786.CustomerMembership.orderNumber'),
          value: 'id',
        },
        {
          text: this.$t('task_29786.CustomerMembership.ApplicationService'),
          value: 'pointExchangeProductName',
        },
        {
          text: '申込者',
          value: 'pointExchangeName'
        },
        {
          text: this.$t('task_29786.CustomerMembership.Number'),
          value: 'quantity',
        },
        {
          text: this.$t(
            'task_29786.CustomerMembership.TicketIssuerEmailOpenConfirmation',
          ),
          value: 'confirmedAt',
        },
        {
          text: this.$t(
            'task_29786.CustomerMembership.MessageSendingDateAndTime',
          ),
          value: 'emailHistory',
        },
      ],
      selected: [],
      headerClientAnnualFeeList: [
        {
          text: '年',
          value: 'year',
        },
        {
          text: '予定請求月',
          value: 'month',
        },
        {
          text: this.$t('task_29786.CustomerMembership.status'),
          value: 'status',
        },
        {
          text: '請求作成日',
          value: 'processedAt',
        },
        {
          text: this.$t('task_29786.CustomerMembership.PaymentRemarks'),
          value: 'remarks',
        },
        {
          text: '年会費',
          value: 'annualFee',
        },
        {
          text: this.$t('task_29786.CustomerMembership.consumptionTax'),
          value: 'annualFeeTax',
        },
        {
          text: 'メンバーシップフィー',
          value: 'membershipFee',
        },
        {
          text: this.$t('task_29786.CustomerMembership.consumptionTax'),
          value: 'membershipFeeTax',
        },
        {
          text: '管理費',
          value: 'managementFee',
        },
        {
          text: this.$t('task_29786.CustomerMembership.consumptionTax'),
          value: 'managementFeeTax',
        },
        {
          text: '温泉維持費',
          value: 'hotspringFee',
        },
        {
          text: this.$t('task_29786.CustomerMembership.consumptionTax'),
          value: 'hotspringFeeTax',
        },
        {
          text: this.$t('task_29786.CustomerMembership.Change'),
          value: 'edit',
        },
      ],
      headerTvphistoryListCustomerMc: [
        {
          text: '日時',
          value: 'createdAt',
          width: '200px',
        },
        {
          text: '処理',
          value: 'changeReason',
          width: '250px',
        },
        {
          text: '宿泊日',
          value: 'stayDateRange',
          width: '250px',
        },
        {
          text: '宿泊施設',
          value: 'facilityName',
          width: '250px',
        },
        {
          text: '増減',
          value: 'pointChange',
          width: '250px',
        },
        {
          text: '備考',
          value: 'remarks',
        },
      ],
      ready: false,
      totalCount: 0,
      defaultYearSelected: null,
      familyList: [],
      subType: '',
      originalMemberUserEmail: null,
      originalSubMemberEmails: {}
    };
  },

  computed: {
    ...mapGetters([
      'getTotalClientAnnualfeeListCustomerMembershipContract',
      'getTotalPointexchangelistCustomerMc',
      'clientAnnualfeeListCustomerMembershipContract',
      'contractlistCustomerMembershipContract',
      'clientMemberInfoCustomerMembershipContract',
      'enumPersonrelationshipListCustomerMc',
      'enumPrefectureListCustomerMc',
      'tvphistoryListCustomerMc',
      'clientMemberInfoCustomerMCSubmember',
      'clientMemberInfoCustomerMCFamily',
      'pointexchangelistCustomerMc',
      'requestMemberMagazineMailCustomerMC',
      'statusSaveData',
      'IDVmTentativeContractCustomerMc',
      'IDVsTentativeContractCustomerMc',
      'getFlagUpdate',
      'getFlagTvphistoryListCustomerMc'
    ]),

    clientId() {
      return parseInt(this.$route.params.id)
    },

    years() {
      const arr = [];
      const yearNow = parseInt(new Date().getFullYear());
      const yearStart = yearNow + 10;
      const yearEnd = yearNow - 10;
      for (let i = yearEnd; i <= yearStart; i++) {
        arr.push({ id: i, name: i + '年' });
      }
      return arr;
    },

    memberId: {
      get() {
        if (this.data.memberId) return this.data.memberId;
        else {
          return '';
        }
      },
    },

    memberUserEmail: {
      get() {
        if (this.data) return this.data.email;
        else {
          return null;
        }
      },
      set(value) {
        if (this.data) {
          this.data.email = value
        }
        this.setEmailCustomerMC(value);
      },
    },

    requestMemberMagazineMail: {
      get() {
        return this.data.requestMemberMagazineMail;
      },
      set(value) {
        this.setRequestMemberMagazineMailCustomerMC(value);
      },
    },

    requestBrochureMail: {
      get() {
        return this.data.requestBrochureMail;
      },
      set(value) {
        this.setRequestBrochureMailCustomerMC(value);
      },
    },

    accommodationFeePayment: {
      get() {
        if (this.data.accommodationFeePayment) return this.data.accommodationFeePayment;
        else return null;
      },
      set(value) {
        this.setAccommodationFeePayment(value);
      },
    },

    facilityFeePayment: {
      get() {
        if (this.data.facilityFeePayment) return this.data.facilityFeePayment;
        else return null;
      },
      set(value) {
        this.setFacilityFeePayment(value);
      },
    },

    setupFeePayment: {
      get() {
        if (this.data.setupFeePayment) return this.data.setupFeePayment;
        else return null;
      },
      set(value) {
        this.setSetupFeePayment(value);
      },
    },

    optionalCleaningPayment: {
      get() {
        if (this.data.optionalCleaningPayment)
          return this.data.optionalCleaningPayment;
        else return null;
      },
      set(value) {
        this.setOptionalCleaningPayment(value);
      },
    },

    taxPayment: {
      get() {
        if (this.data.taxPayment) return this.data.taxPayment;
        else return null;
      },
      set(value) {
        this.setTaxPayment(value);
      },
    },

    otherServicePayment: {
      get() {
        if (this.data.otherServicePayment) return this.data.otherServicePayment;
        else return null;
      },
      set(value) {
        this.setOtherServicePayment(value);
      },
    },

    memberUserEmailCannotBeRemoved() {
      if (this.originalMemberUserEmail) {
        // if the email address is set on load it cannot be removed
        return v => !!v || 'ログインメールアドレスは削除できません。';
      }
      return () => true;
    },
  },

  async mounted() {
    // Table 契約
    this.getContractlistCustomerMembershipContract(this.$route.params.id);
    // 会員情報
    this.getClientMemberInfoCustomerMembershipContract(this.$route.params.id);
    this.ready = true;

    this.getEnumPrefectureListCustomerMc();
    this.getEnumPersonrelationshipListCustomerMc();
  },

  watch: {
    getFlagUpdate() {
      this.$refs.tableClientAnnualFeeList.reset()
    },

    getFlagTvphistoryListCustomerMc() {
      this.$refs.tableTvphistoryListCustomerMc.reset();
    },

    clientMemberInfoCustomerMembershipContract() {
      this.data = this.clientMemberInfoCustomerMembershipContract;
    },

    clientMemberInfoCustomerMCSubmember() {
      this.dataSubmember = this.clientMemberInfoCustomerMCSubmember;
    },

    clientMemberInfoCustomerMCFamily() {
      this.dataFamily = this.clientMemberInfoCustomerMCFamily;
    },

    IDVmTentativeContractCustomerMc() {
      const url = '/contract/usage-situation-vm';
      const routeData = this.$router.resolve({
        path: `${url}/${this.IDVmTentativeContractCustomerMc}/VM`,
      });
      window.open(routeData.href, '_blank');

      // window.open(
      //   '/vm-temporary-contract/' +
      //     this.$route.params.id +
      //     '?contractItem=' +
      //     this.IDVmTentativeContractCustomerMc +
      //     '&type=VM',
      // );
    },

    IDVsTentativeContractCustomerMc() {
      const url = '/contract/vs-vs';
      const routeData = this.$router.resolve({
        path: `${url}/${this.IDVsTentativeContractCustomerMc}/VS?clientId=${this.$route.params.id}&contractId=${this.IDVsTentativeContractCustomerMc}`,
      });
      window.open(routeData.href, '_blank');
      // window.open(
      //   '/vm-temporary-contract/' +
      //     this.$route.params.id +
      //     '?contractItem=' +
      //     this.IDVsTentativeContractCustomerMc +
      //     '&type=VS',
      // );
    },
    data () {
      // must retain the original login email address
      this.originalMemberUserEmail = this.memberUserEmail
      this.originalSubMemberEmails = this.data.subMembers.reduce((result, sub) => {
        result[sub.id] = sub.memberUser?.email
        return result;
      }, {});
    }
  },

  methods: {
    getAgeFromDate,
    ...mapActions([
      'getClientAnnualfeeListCustomerMembershipContract',
      'getContractlistCustomerMembershipContract',
      'getClientMemberInfoCustomerMembershipContract',
      'getEnumPersonrelationshipListCustomerMc',
      'getEnumPrefectureListCustomerMc',
      'getTvphistoryListCustomerMc',
      'getPointexchangelistCustomerMc',
      'deleteannualfeeCustomerMc',
      'updatememberuserCustomerMC',
      'createVmTentativeContractCustomerMc',
      'createVsTentativeContractCustomerMc',
      'actionDeleteSubmember',
    ]),
    ...mapMutations([
      'setOptionalCleaningPayment',
      'setOtherServicePayment',
      'setTaxPayment',
      'setOptionalCleaningPaymen',
      'setEmailCustomerMC',
      'setRequestBrochureMailCustomerMC',
      'setRequestMemberMagazineMailCustomerMC',
      'setDataSubmembers',
      'setSetupFeePayment',
      'setAccommodationFeePayment',
      'setFacilityFeePayment',
      'setDataChangeMember',
      'setAlertSuccess',
      'setAlertError',
      'changeDataCustomerMc',
      'setRequestMemberMagazineMail',
      'setClientMemberInfoCustomerMCFamily',
    ]),

    showDialogChangeAnnualFee(item) {
      this.visible = 3;
      this.data = item;
      this.setDataChangeMember(item);
      this.idxEdit += 1;
    },

    async loadDataAnnualFee(variables) {
      if(variables){
        const data = {
          id: this.$route.params.id,
          paginate: variables.pagination,
        };
        await this.getClientAnnualfeeListCustomerMembershipContract(data);
      }
    },

    saveBirthDay(date) {
      this.$refs.menu.save(date);
    },
    saveBirthDay2(date) {
      this.$refs.menu2.save(date);
    },

    getUrl(item) {
      let url = '';
      // const url = '/contract/basic-infomation';
      if (item.productType.code === 'VS') {
        url = '/contract/vs-vs';
      } else if (item.productType.code === 'PW') {
        url = '/contract/usage-situation-sp-pw';
      } else if (item.productType.code === 'MW') {
        url = '/contract/usage-situation-mw';
      } else if (item.productType.code === 'VM') {
        url = '/contract/usage-situation-vm';
      } else {
        url = '/contract/usage-situation-sp-pw';
      }
      let linkFull = `${url}/${item.id}/${item.productType.code}`;
      if (item.productType.code === 'VS' && item.status !== '仮契約') {
        linkFull += `?clientId=${this.$route.params.id}&contractId=${item.id}`;
      }
      if (item.productType.code === 'VM' && item.status !== '仮契約') {
        linkFull += `?clientId=${this.$route.params.id}&contractId=${item.id}`;
      }
      window.open(linkFull);
    },

    changeEmail(event) {
      if (event !== this.memberUserEmail) {
        this.objResetEmail.newEmail = event;
      } else delete this.objResetEmail.newEmail;
    },

    setDialog(value) {
      switch (value) {
        case 1:
          this.textDialogAlert = 'VM契約を作成しますか';
          this.functionHandle = value;
          break;
        case 0:
          this.textDialogAlert = '削除しますか？';
          this.functionHandle = value;
          break;
        case 2:
          this.textDialogAlert = 'VS契約を作成しますか';
          this.functionHandle = value;
          break;
        default:
        // code block
      }
      this.dialog = true;
    },

    async runFunc(value) {
      this.dialog = false;
      switch (value) {
        case 1:
          await this.createVmTentativeContractCustomerMc(this.$route.params.id);
          break;
        case 0:
          this.del();
          break;
        case 2:
          await this.createVsTentativeContractCustomerMc(this.$route.params.id);
          break;
        default:
        // code block
      }
    },

    del() {
      this.selected.forEach(e => {
        this.deleteannualfeeCustomerMc(e.id);
      });
    },

    showDialog(visible) {
      const findContract = this.contractlistCustomerMembershipContract.find(
        e =>
          (e.productType.code === 'VM' || e.productType.code === 'VS') &&
          e.status === '有効',
      );
      if (findContract) {
        this.dataSendPMS = {
          id: findContract.id,
          code: findContract.productType.code,
        };
        this.pointsAfter =
          this.tvphistoryListCustomerMc.length > 0
            ? this.tvphistoryListCustomerMc[0].pointsAfter
            : 1;
        this.visible = visible;
      } else {
        this.setAlertError(
          'VS/VMの契約がないためTVPでのポイント連携はできません',
        );
      }
    },

    dialogCreateSubType(visible, subType) {
      this.subType = subType;
      this.visible = visible;
    },

    async saveData() {
      var arr = [];
      this.dataSubmember.forEach(e => {
        arr.push(e);
      });
      this.dataFamily.forEach(e => {
        arr.push(e);
      });
      this.setDataSubmembers(arr);
      await this.updatememberuserCustomerMC(this.$route.params.id);
    },

    async loadList(variables) {
      if (variables) {
        if (!this.ready) return;
        const { total } = await this.getTvphistoryListCustomerMc({
          id: this.$route.params.id,
          take: variables.pagination.take,
          skip: variables.pagination.skip,
        });
        this.totalCount = total;
      }
    },

    async loadDataPointexchangelistCustomerMc(variables) {
      const data = {
        id: this.$route.params.id,
        paginate: variables.pagination,
      };
      await this.getPointexchangelistCustomerMc(data);
    },

    async deleteFamilyMember(item) {
      const { warnings } = await this.$store.dispatch('getSubMemberDeleteWarning', item.id)
      const warning = warnings.map(w => w.message).join('\n');
      const memberType = item.subMemberType.name;

      // if there's warning, show only that and ok should do nothing
      if (warning) {
        this.$alert({
          title: `${memberType}削除`,
          message: null,
          warning,
        })
      } else {
        const message = `選択された${memberType}を削除します。よろしいですか？`;

        this.$confirm({
          title: `${memberType}削除`,
          message,
          warning,
          ok: async () => {
            await this.actionDeleteSubmember(item.id).then(isDeleteSubMember => {
              const newFamilyList = this.familyList.filter(e => e.id !== item.id);
              this.familyList = newFamilyList;
              const newDataFamily = this.dataFamily.filter(e => e.id !== item.id);
              this.dataFamily = newDataFamily;
              this.dataSubmember = this.dataSubmember.filter(e => e.id !== item.id);
            });
          },
        })
      }
    },

    async sendRegistrationEmail(item) {
      const message = '選択されたファミリー会員に登録完了メールを送ります。よろしいですか？'
      this.$confirm({
        title: '登録完了メール送信',
        message: message,
        ok: async () => {
          await this.$store.dispatch('sendSubMemberRegisterEmail', item.id)
        },
      })
    },

    async restoreOriginalEmailIfNeeded() {
      // if the email is removed then we restore id to the original
      // even wihtout this the update is successful but the email address won't be changed
      // so user might think that the email address has been removed when it's not
      await this.$nextTick();
      if (
        !this.memberUserEmail &&
        this.memberUserEmailCannotBeRemoved(this.memberUserEmail) !== true
      ) {
        this.memberUserEmail = this.originalMemberUserEmail;
      }
    },

    async restoreOriginalSubMemberEmailIfNeeded (subMember) {
      // requested in TO2020-583, disallow deletion of login email
      if (!subMember.id) return
      if (!subMember.memberUser?.email) {
        const original = this.originalSubMemberEmails[subMember.id];
        if (original) {
          this.$set(subMember.memberUser, 'email', original);
        }
      };
    },

    subMemberUserEmailCannotBeRemoved(subMember) {
      if (this.originalSubMemberEmails[subMember.id]) {
        // if the email address is set on load it cannot be removed
        return v => !!v || 'ログインメールアドレスは削除できません。';
      }
      return () => true;
    },

    setLoginEmail(row, email) {
      if (!row.memberUser) {
        this.$set(row, 'memberUser', {});
      }

      this.$set(row.memberUser, 'email', email);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 20px !important;
  color: #0c6786;
  font-weight: bold !important;
}
h3 {
  font-size: 18px !important;
  color: #0c6786 !important;
  font-weight: 500 !important;
}
h4 {
  font-size: 16px !important;
  font-weight: 400;
  color: #232222 !important;
}
h6 {
  font-size: 10px;
  font-weight: 600;
  color: #000000;
}
.color-blue--drank {
  color: #004f8b !important;
}
.color-bl--ob {
  color: #0b6786 !important;
}
.color-bl {
  color: #424242 !important;
}
.t-btn--prm {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
}
.component {
  &-header,
  &-main {
    font-size: var(--unnamed-font-size-16);
    color: var(--text_active_000000);
    font-weight: 500;
  }
}

.button-add-family-members {
  font-size: 14px;
  height: 32px !important;
  width: 200px !important;
  border-color: #4fa9db;
  font-weight: 400;
  color: #13ace0 !important;
}

@media (max-width: 600px) {
  .v-card__title {
    flex-direction: column;
    align-items: flex-end;
  }
}
.divMemberInformation {
  width: 90%;
  padding: 10px;
  margin: auto;
  .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      .v-card {
        border: unset !important;
        box-shadow: unset !important;
        padding: 0 !important;
      }
    }
  }
}

.selectCustom {
  width: 10%;
  margin-right: 10px;
}
.v-select-custom {
  height: 36px;
  padding-top: 5px;
}
.family-member-title {
  color: #0c6786;
  font-size: 18px;
}
.expan-title {
  font-size: 14px;
  color: #232222;
}

.t-12-c-4x6 {
  margin-top: 0 !important;
  ::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-text-field__slot {
      input {
        font-size: 14px !important;
        color: #444444 !important;
      }
    }
    .v-select__selection--comma {
      font-size: 14px !important;
      color: #444444 !important;
    }
  }
}

.button-delete-family-members {
  font-size: 10px;
  height: 26px !important;
  width: 120px !important;
  background-color: #c92d2d !important;
  color: #ffffff;
}

.button-send-email{
  font-size: 10px;
  height: 26px !important;
  width: 120px !important;
  background-color: var(--button_primary) !important;
  color: #ffffff;
}

.sub-member-name {
  width: 50px;
}

.top-text {
  .v-text-field.v-input--dense .v-label {
    top: -15px;
  }
  .v-text-field.v-input--dense .v-label--active {
    top: 0 !important;
  }
}
label {
  font-size: 14px !important;
  font-weight: 500;
  color: #444444 !important;
}
::v-deep {
  .annual-fee-table thead > tr > th:first-child .v-data-table__checkbox {
    display: table-column !important;
  }
  .annual-fee-table {
    tbody {
      tr {
        td {
          color: #232222 !important;
        }
      }
    }
  }

  // .v-data-footer {
  //   .v-data-footer__select {
  //     font-size: 13px !important;
  //     font-weight: 500;
  //     color: #000000;
  //   }

  //   .v-select__selections {
  //     .v-select__selection--comma {
  //       font-size: 13px !important;
  //       font-weight: 500;
  //       color: #000000 !important;
  //     }
  //   }
  //   .v-icon__svg {
  //     color: #333 !important;
  //   }
  // }

  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-text-field__slot {
    input {
      font-size: 14px !important;
      color: #444444 !important;
    }
  }
  .v-select__selection--comma {
    font-size: 14px !important;
    color: #444444 !important;
  }
  // table {
  //   thead {
  //     tr {
  //       th {
  //         font-weight: bold;
  //         font-size: 14px !important;
  //         color: #757575 !important;
  //       }
  //     }
  //   }
  //   tbody {
  //     tr {
  //       td {
  //         font-size: 16px !important;
  //         color: #757575 !important;
  //         font-weight: 400;
  //       }
  //     }
  //   }
  // }
  // .v-data-footer {
  //   font-size: 13px;
  //   color: #000000;
  //   font-weight: 500;
  // }
  // .v-data-footer__pagination {
  //   display: none;
  // }
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
.sudden-vacations-point {
  color: #004f8b !important;
  font-size: 20px !important;
}
.t14-c42x3 {
  font-size: 14px;
  color: #424242;
}
.buy-gift-tickets-button {
  font-size: 12px !important;
  height: 32px !important;
  width: 140px !important;
}
.add-use-cancel-button {
  font-size: 12px !important;
  height: 32px !important;
  width: 132px !important;
}
.making-reservations-for-affiliated-facilities-button {
  font-size: 12px !important;
  height: 32px !important;
  width: 116px !important;
  color: #fff;
}
// .table-header-vacations-point {
//   ::v-deep {
//     table {
//       thead {
//         tr {
//           th {
//             font-weight: 400;
//             font-size: 14px !important;
//             color: #424242 !important;
//           }
//         }
//       }
//       tbody {
//         tr {
//           td {
//             font-size: 16px !important;
//             color: #424242 !important;
//             font-weight: 400;
//           }
//         }
//       }
//     }
//   }
// }
.t14-c3083dc {
  font-size: 14px;
  color: #3083dc;
}
.t14-cdb3069 {
  font-size: 14px;
  color: #db3069;
}
.annual-fee-title {
  margin-top: 1px;
  color: #0c6786;
  font-size: 20px;
}
.delete-selected-year-button {
  font-size: 14px !important;
  height: 31px !important;
  width: 136px !important;
  background-color: #c92d2d !important;
  color: #fff;
}
.select-year {
  height: 33px !important;
  width: 84px !important;
  margin-right: 15px;
  ::v-deep {
    .v-input__slot {
      height: 33px !important;
      width: 84px !important;
      min-height: 33px !important;

      .v-select__slot {
        line-height: 12px !important;
        label {
          font-size: 10px !important;
          color: #000000 !important;
          top: 6px;
        }

        .v-select__selections {
          padding: 0 !important;
          .v-select__selection--comma {
            font-size: 10px !important;
            color: #000000 !important;
            margin: 0 4px 0 0;
          }
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          left: 38px;
          top: -14px !important;
          position: absolute;
        }
      }
    }
  }
}
.add-selected-year-button {
  height: 31px !important;
  width: 136px !important;
  ::v-deep {
    .v-btn__content {
      font-size: 14px !important;
    }
  }
}
.t20-c0C6786 {
  font-size: 20px !important;
  color: #0c6786 !important;
}
.gif-ticket-order-confirmation-list {
  font-size: 20px !important;
  color: #0b6786 !important;
}
.vs-vm-button {
  font-size: 14px !important;
  width: 126px !important;
  height: 32px !important;
}
.send-password-setting-link {
  font-size: 14px !important;
  height: 32px !important;
  width: 230px !important;
  border-color: #4fa9db !important;
}

.cbox {
  cursor: pointer;
  display: flex;
  input {
    width: 18px;
    height: 18px;
    background-color: #ffffff !important;
    border: 1px solid #000000 !important;
  }
  .check-box-text {
    margin-top: -2px;
    margin-left: 10px;
    font-size: 14px !important;
    color: #000000 !important;
  }
}
.t18-c0C6786 {
  font-size: 18px !important;
  color: #0c6786 !important;
}
</style>
