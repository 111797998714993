<template>
  <div>
    <v-dialog v-model="dialog">
      <!-- alet -->
      <h1 class="text-header">{{ data.year }}年年会費変更</h1>
      <template>
        <div
          v-if="data.processedAt === null"
          class="d-flex justify-space-between pa-2 div-alert"
        >
          <div class="mt-1">
            <i class="icon-alert fas fa-exclamation-triangle"></i>
            <span class="ml-2 text-alert">未請求の年会費です</span>
          </div>
          <v-btn
            class="btn-billing btn-crm-primary text-14px"
            style="font-size: 14px !important"
            @click="changeStatus(data.id)"
          >
            請求作成
          </v-btn>
        </div>
      </template>
      <!-- end alet -->
      <!-- status -->
      <div class="d-flex mt-2">
        <div>
          <h4 class="text-10px text-header-field">ステータス</h4>
          <p class="text-14px mt-2 text-data-field">
            {{ data.processedAt === null ? '未請求' : '請求作成済み' }}
          </p>
        </div>
      </div>
      <!-- end status -->
      <!-- Content -->
      <div>
        <h4 class="text-10px text-header-field">支払い備考</h4>
        <v-text-field
          style="width: 73%"
          v-model="getRemarksChangeMember"
          dense
        ></v-text-field>
      </div>
      <!-- Date -->
      <div v-if="data.processedAt !== null" class="d-flex mt-2">
        <div>
          <h4 class="text-10px text-header-field">年会費請求作成日</h4>
          <p class="text-14px mt-2 text-data-field">{{ data.processedAt }}</p>
        </div>
      </div>
      <!-- End Date -->
      <!-- if data.processedAt === null -->
      <div v-if="data.processedAt === null">
        <div>
          <h4 class="text-10px text-header-field">予定請求月</h4>
          <v-select
            style="width: 73%"
            dense
            v-model="getMonthChangeMember"
            item-text="name"
            item-value="id"
            :items="month"
          ></v-select>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">年会費</h4>
              <v-text-field
                dense
                :reverse="true"
                :type="statusAnnualFee ? 'number' : 'text'"
                @focus="
                  statusAnnualFee = true;
                  getFocusAnnualPrice();
                "
                @blur="
                  statusAnnualFee = false;
                  getBlurAnnualPrice();
                "
                @change="changeAnnualFee($event)"
                v-model="getAnnualFeeChangeMember"
              ></v-text-field>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p v-if="!statusChangeAnnual" class="mt-2 text-data-field">
                {{ getAnnualFeeTaxChangeMember }}
              </p>
              <p v-else class="mt-2 text-data-field">
                {{
                  annualFeeTax
                    ? annualFeeTax.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      })
                    : '¥0,00'
                }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">メンバーシップフィー</h4>
              <v-select
                dense
                :items="itemMembershipFee"
                item-value="id"
                item-text="name"
                @change="changeMembershipFee($event)"
                :value="data.membershipFee"
              >
              </v-select>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p v-if="!statusChangeMembershipFee" class="mt-2 text-data-field">
                {{ getMembershipFeeTaxChangeMember }}
              </p>
              <p v-else class="mt-2 text-data-field">
                {{
                  memberShipFee
                    ? memberShipFee.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      })
                    : '¥0,00'
                }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">管理費</h4>
              <v-text-field
                dense
                :reverse="true"
                :type="statusManagementFee ? 'number' : 'text'"
                @focus="
                  statusManagementFee = true;
                  getFocusManagementPrice();
                "
                @blur="
                  statusManagementFee = false;
                  getBlurManagementPrice();
                "
                @change="changeManagementFeeChangeMember($event)"
                v-model="getManagementFeeChangeMember"
              ></v-text-field>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p
                v-if="!statusChangeManagementFeeChangeMember"
                class="mt-2 text-data-field"
              >
                {{ getManagementFeeTaxChangeMember }}
              </p>
              <p v-else class="mt-2 text-data-field">
                {{
                  manaFeeChangeMember
                    ? manaFeeChangeMember.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      })
                    : '¥0,00'
                }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">温泉維持費</h4>
              <v-text-field
                dense
                :reverse="true"
                :type="statusHotspringFee ? 'number' : 'text'"
                @focus="statusHotspringFee = true"
                @blur="statusHotspringFee = false"
                v-model="getHotspringFeeChangeMember"
                @change="changeHotspringFeeChangeMember($event)"
              ></v-text-field>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p
                v-if="!statusChangeHotspringFeeChangeMember"
                class="mt-2 text-data-field"
              >
                {{ getHotspringFeeTaxChangeMember }}
              </p>
              <p v-else class="mt-2 text-data-field">
                {{
                  hotFeeChangeMember
                    ? hotFeeChangeMember.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      })
                    : '¥0,00'
                }}
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- if data.processedAt !== null -->
      <div v-else>
        <div>
          <h4 class="text-10px text-header-field">予定請求月</h4>
          <p class="mt-2 text-data-field">{{ data.month }}月</p>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">年会費</h4>
              <p class="mt-2 text-data-field" style="text-align: right">
                ¥{{ parseFloat(data.annualFee).toFixed(2) }}
              </p>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p class="mt-2 text-data-field">
                ¥{{ parseFloat(data.annualFeeTax).toFixed(2) }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">メンバーシップフィー</h4>
              <p class="mt-2 text-data-field" style="text-align: right">
                ¥{{ parseFloat(data.membershipFee).toFixed(2) }}
              </p>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p class="mt-2 text-data-field">
                ¥{{ parseFloat(data.membershipFeeTax).toFixed(2) }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">管理費</h4>
              <p class="mt-2 text-data-field" style="text-align: right">
                ¥{{ parseFloat(data.managementFee).toFixed(2) }}
              </p>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p class="mt-2 text-data-field">
                ¥{{ parseFloat(data.managementFeeTax).toFixed(2) }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="9">
              <h4 class="text-10px text-header-field">温泉維持費</h4>
              <p class="mt-2 text-data-field" style="text-align: right">
                ¥{{ parseFloat(data.hotspringFee).toFixed(2) }}
              </p>
            </v-col>
            <v-col :cols="3">
              <h4 class="text-10px text-header-field">消費税</h4>
              <p class="mt-2 text-data-field">
                ¥{{ parseFloat(data.hotspringFeeTax).toFixed(2) }}
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- End Content -->
      <div class="d-flex justify-end mt-2">
        <v-btn
          class="t-btn--red-dark btn-dialog mr-2"
          width="72"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
          {{ $t('task_29786.close') }}
        </v-btn>
        <v-btn class="t-btn--prm btn-dialog" width="68" @click="save">
          {{ $t('task_29786.keep') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { SALES_TAX } from '@/api/graphql/customer/response/detail/membershipContract/CustomerMembershipContract/index.js';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import gql from 'graphql-tag';

export default {
  name: 'TicketsBuy',
  data() {
    return {
      statusChangeHotspringFeeChangeMember: false,
      statusChangeManagementFeeChangeMember: false,
      statusChangeAnnual: false,
      statusChangeMembershipFee: false,
      salesTax: 10,
      hotFeeChangeMember: null,
      manaFeeChangeMember: null,
      memberShipFee: null,
      annualFeeTax: null,
      itemMembershipFee: [
        { id: 0, name: '¥0' },
        { id: 24000, name: '¥24,000' },
      ],
      statusAnnualFee: false,
      statusManagementFee: false,
      statusHotspringFee: false,

      // Billing
      checkBilling: false,
    };
  },
  props: {
    visible: Boolean,
    data: Object,
  },
  computed: {
    month() {
      var arr = [];
      for (let i = 1; i <= 12; i++) {
        arr.push({ id: i, name: i + '月' });
      }
      return arr;
    },
    ...mapGetters([
      'monthChangeMember',
      'annualFeeChangeMember',
      'annualFeeTaxChangeMember',
      'membershipFeeChangeMember',
      'membershipFeeTaxChangeMember',
      'managementFeeChangeMember',
      'managementFeeTaxChangeMember',
      'hotspringFeeChangeMember',
      'hotspringFeeTaxChangeMember',
      'remarksChangeMember',
    ]),
    getMonthChangeMember: {
      get() {
        return this.monthChangeMember;
      },
      set(value) {
        this.setMonthChangeMember(value);
      },
    },
    getAnnualFeeChangeMember: {
      get() {
        return this.formatMoney(
          this.statusAnnualFee,
          this.annualFeeChangeMember,
        );
      },
      set(value) {
        this.setAnnualFeeChangeMember(value);
      },
    },
    getAnnualFeeTaxChangeMember: {
      get() {
        return parseFloat(this.annualFeeTaxChangeMember).toLocaleString(
          'ja-JP',
          { style: 'currency', currency: 'JPY' },
        );
      },
      set(value) {
        this.setAnnualFeeTaxChangeMember(value);
      },
    },
    getMembershipFeeChangeMember: {
      get() {
        if (this.membershipFeeChangeMember === 0)
          return { id: this.membershipFeeChangeMember, name: '¥0' };
        else
          return { id: this.membershipFeeChangeMember, name: '¥24,000' };
      },
      set(value) {
        this.setMembershipFeeChangeMember(value);
      },
    },
    getMembershipFeeTaxChangeMember: {
      get() {
        return parseFloat(this.membershipFeeTaxChangeMember).toLocaleString(
          'ja-JP',
          { style: 'currency', currency: 'JPY' },
        );
      },
      set(value) {
        this.setMembershipFeeTaxChangeMember(value);
      },
    },
    getManagementFeeChangeMember: {
      get() {
        return this.formatMoney(
          this.statusManagementFee,
          this.managementFeeChangeMember,
        );
      },
      set(value) {
        this.setManagementFeeChangeMember(value);
      },
    },
    getManagementFeeTaxChangeMember: {
      get() {
        return parseFloat(this.managementFeeTaxChangeMember).toLocaleString(
          'ja-JP',
          { style: 'currency', currency: 'JPY' },
        );
      },
      set(value) {
        this.setManagementFeeTaxChangeMember(value);
      },
    },
    getHotspringFeeChangeMember: {
      get() {
        return this.formatMoney(
          this.statusHotspringFee,
          this.hotspringFeeChangeMember,
        );
      },
      set(value) {
        this.setHotspringFeeChangeMember(value);
      },
    },
    getHotspringFeeTaxChangeMember: {
      get() {
        return parseFloat(this.hotspringFeeTaxChangeMember).toLocaleString(
          'ja-JP',
          { style: 'currency', currency: 'JPY' },
        );
      },
      set(value) {
        this.setHotspringFeeTaxChangeMember(value);
      },
    },
    getRemarksChangeMember: {
      get() {
        return this.remarksChangeMember;
      },
      set(value) {
        this.setRemarksChangeMember(value);
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(data) {
        this.changeMembershipFee(data.membershipFee)
      }
    }
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'updateAnnualfeeChangeMember',
      'getClientAnnualfeeListCustomerMembershipContract',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setMonthChangeMember',
      'setAnnualFeeChangeMember',
      'setAnnualFeeTaxChangeMember',
      'setMembershipFeeChangeMember',
      'setMembershipFeeTaxChangeMember',
      'setManagementFeeChangeMember',
      'setManagementFeeTaxChangeMember',
      'setHotspringFeeChangeMember',
      'setHotspringFeeTaxChangeMember',
      'setRemarksChangeMember',
      'setClientIdChangeMember',
      'setPermissionUser',
      'setRoleAdminUser',
      'setAlertError',
    ]),

    getFocusAnnualPrice() {
      if (this.getAnnualFeeChangeMember === 0) {
        this.getAnnualFeeChangeMember = null;
      }
    },

    getBlurAnnualPrice() {
      if (this.getAnnualFeeChangeMember === '￥NaN') {
        this.getAnnualFeeChangeMember = 0;
      }
    },

    getFocusManagementPrice() {
      if (this.getManagementFeeChangeMember === 0) {
        this.getManagementFeeChangeMember = null;
      }
    },

    getBlurManagementPrice() {
      if (this.getManagementFeeChangeMember === '￥NaN') {
        this.getManagementFeeChangeMember = 0;
      }
    },

    async calculateSalesTax(price) {
      var getFullTime = null;
      if (this.getMonthChangeMember === '' || this.getMonthChangeMember === 0) {
        getFullTime = this.data.year + '-' + '01-01';
      } else {
        if (this.getMonthChangeMember > 9) {
          getFullTime =
            this.data.year + '-' + this.getMonthChangeMember + '-' + '01';
        } else
          getFullTime =
            this.data.year + '-0' + this.getMonthChangeMember + '-' + '01';
      }
      const variables = {
        price: Number(price),
        taxType: 'Normal',
        date: getFullTime,
      };
      return await this.$apollo
        .query({
          query: gql`
            ${SALES_TAX}
          `,
          variables: variables,
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              SALES_TAX,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
          throw error
        });
    },

    async changeAnnualFee(e) {
      await this.calculateSalesTax(e)
        .then((data) => {
          this.getAnnualFeeTaxChangeMember = data.data.salesTax.salesTax
          this.annualFeeTax = data.data.salesTax.salesTax;
        })
    },

    async changeMembershipFee(e) {
      this.statusChangeMembershipFee = true;
      var getFullTime = null;
      if (this.getMonthChangeMember === '' || this.getMonthChangeMember === 0) {
        getFullTime = this.data.year + '-' + '01-01';
      } else {
        if (this.getMonthChangeMember > 9) {
          getFullTime =
            this.data.year + '-' + this.getMonthChangeMember + '-' + '01';
        } else
          getFullTime =
            this.data.year + '-0' + this.getMonthChangeMember + '-' + '01';
      }
      const variables = {
        price: e,
        taxType: 'Normal',
        date: getFullTime,
      };
      await this.$apollo
        .query({
          query: gql`
            ${SALES_TAX}
          `,
          variables: variables,
        })
        .then((data) => {
          this.getMembershipFeeChangeMember = data.data.salesTax.priceWithoutSalesTax;
          this.memberShipFee = data.data.salesTax.salesTax;
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              SALES_TAX,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async changeManagementFeeChangeMember(e) {
      this.statusChangeManagementFeeChangeMember = true;
      this.statusChangeMembershipFee = true;
      await this.calculateSalesTax(e)
        .then((data) => {
          this.manaFeeChangeMember = data.data.salesTax.salesTax;
        })
    },

    async changeHotspringFeeChangeMember(e) {
      this.statusChangeHotspringFeeChangeMember = true;
      this.statusChangeMembershipFee = true;
      await this.calculateSalesTax(e)
        .then((data) => {
          this.hotFeeChangeMember = data.data.salesTax.salesTax;
        })
    },

    formatMoney(status, val) {
      if (status === false) {
        if (val.length === 0) {
          val = 0;
        }
        return parseFloat(val).toLocaleString('ja-JP', {
          style: 'currency',
          currency: 'JPY',
        });
      }
      return val;
    },

    save() {
      this.updateAnnualfeeChangeMember(this.data.year);
      this.$emit('close');
      this.$emit('reloadData');
    },

    closeDialog() {
      this.$emit('close');
    },

    async changeStatus(id) {
      this.$store.commit('setLoadingOverlayShow');
      this.data.processedAt = new Date().toISOString().substr(0, 10);
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($ids: [Int!]!) {
              processAnnualFee(ids: $ids) {
                processedCount
                errorCount
              }
            }
          `,
          variables: {
            ids: parseInt(id),
          },
        })
        .then((data) => {
          this.$store.commit('setLoadingOverlayHide');
          this.$emit('resetNotClose');
        })
        .catch(async (error) => {
          this.$store.commit('setLoadingOverlayHide');
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .text-14px {
    font-size: 14px !important;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .v-text-field__slot {
    input {
      font-size: 12px;
      color: #444444 !important;
    }
  }
  .v-select__selection {
    font-size: 12px;
    color: #444444 !important;
  }
  .v-dialog {
    width: 350px;
    height: 650px;
    background-color: white;
    padding: 15px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
// ----
.text-header {
  font-size: 28px;
  color: #212121;
}
// div
.div-alert {
  color: #ffffff;
  width: 320px;
  margin-left: -6px;
  height: 46px;
  background-color: #cb396c;
  border-radius: 9px;
}
// icon
.icon-alert {
  font-size: 20px;
}
// Text
.text-alert {
  font-size: 16px;
}
.text-header-field {
  color: #000000;
}
.text-data-field {
  font-size: 14px;
  color: #444444;
}
// button
.btn-billing {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background-color: #cb396c !important;
  width: 73px;
  font-size: 12px !important;
  height: 32px !important;
}
.btn-dialog {
  height: 32px !important;
  font-size: 12px;
  color: #ffffff;
}
</style>
