<template>
  <v-dialog v-model="dialog">
    <h1>パスワード設定リンクを送信します</h1>
    <div class="d-flex justify-end mt-7">
      <v-btn class="t-btn--red-dark" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
        閉じる
      </v-btn>
      <v-btn class="btn-crm-primary ml-4" @click="actionReset()">
        {{ $t('task_29786.CustomerMembership.PasswordSettingLink') }}
      </v-btn>
    </div>
    <setting-link-save :visible="showLink === 1" @close="completed" />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import settingLinkSave from './settingLinkSave';
export default {
  components: { settingLinkSave },
  name: 'SettingLink',
  data() {
    return {
      valid: false,
      row: null,
      isShowDate: false,
      dates: [],
      showLink: -1,
    };
  },
  props: {
    visible: Boolean,
    objResetEmail: Object
    
  },

  watch: {
    getStatus () {
      this.showLink = 1
    }
  },

  computed: {
    ...mapGetters(['getStatus']),
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ');
      },
      set() {
        this.dates.join(' ~ ');
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['getSetting']),
    ...mapMutations(['setSettingLink', 'setAlertSuccess']),

    actionReset () {
      this.objResetEmail.clientId = parseInt(this.$route.params.id)
      this.setSettingLink({objReset: this.objResetEmail})
      this.getSetting()
    },
    
    closeDialog() {
      this.$emit('close');
    },

    completed() {
      this.showLink = -1
      this.closeDialog();
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
.v-btn {
  font-size: 12px !important;
  height: 33px !important;
  i {
    font-size: 14px;
  }
}
.t-btn--red-dark {
  width: 72px !important;
}
.btn-crm-primary {
  max-width: 194px !important;
}
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 512px;
    padding: 17px;
    background-color: #fff !important;
    border-radius: 9px;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
}
</style>
